import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Kenneth Nguyen', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Kenneth',
  subtitle: 'How are you today?',
  cta: 'Get to know me',
};

// ABOUT DATA
export const aboutData = {
  img: 'arb.jpg',
  paragraphOne: 'I\'m a recent graduate from the University of California, Santa Cruz with a Bachelor of Science in Computer Science. I am an aspiring full-stack developer but moreso a front-end enthusiast. I am consistently learning new technologies and skills to improve myself as a programmer. As a developer, my interests lie in creating applications that people will enjoy using to help themselves, others, and/or the environment.',
  paragraphTwo: 'Some of my interests are music, technology, cars, basketball, (american) football, stocks, video games, cooking, Youtube, Reddit, Netflix, hikes, and programming.',
  paragraphThree: 'Looking for a full time software engineer job! If you know any opportunities available, I\'d love to connect!',
  resume: 'https://drive.google.com/file/d/1itSpWYIS_gKcSKqpqaRbkhoYmCSOVHN6/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'frydgeProject.png',
    title: 'Frydge',
    info: 'A web app for users to keep track of existing groceries and their expiration dates, prices, etc. in one or more households. Supports multiple concurrent users, has realtime updates, and is mobile responsive!',
    info2: 'This project was made with 4 other undergraduates for our Senior Capstone project. We all had experiences with not knowing what food or beverages are whose when cleaning out a fridge or determining if those 3 random hot pockets belonged to Dan.',
    tech: 'Technologies: React, Ant Design, Firebase, Context API, CSS',
    url: 'https://frydge.me',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'covid19Project.png',
    title: 'COVID-19 U.S. Tracker',
    info: 'A COVID-19 tracker that visualizes the statistics (total positive cases, recoveries, and deaths) of COVID-19 data in the United States provided by the COVID Tracking Project API.',
    info2: 'I was tired of looking at COVID-19 statistic tracking websites that was fully blown with data around the world, a lot of informational details, and slow-rendering large visuals. This was my solution to engineer a simplisitc tracker for what I wanted to see and a few data visualizations.',
    tech: 'Technologies: React, Material-UI, Chart.js, COVID Tracking Project API',
    url: 'https://covid19-us-tracker-visual.herokuapp.com/',
    repo: 'https://github.com/KennethNguyen/COVID19-US-tracker', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'unluckySplash.png',
    title: 'Unlucky',
    info: 'A social media web app for users to share about their unlucky events and read about others',
    info2: 'This was mostly done as a learning project for me to get familiar with Figma, TypeScript, Redux/Redux Toolkit, and designing a RESTful API. It was challenging to work with all of these with little experience but definitely rewarding and fun!',
    tech: 'Technologies: React, TypeScript, Redux Toolkit, Chakra UI, Node.js, Express, Mongoose, MongoDB, JWT, Figma',
    url: 'https://unlucky-social-media.netlify.app/',
    repo: 'https://github.com/KennethNguyen/unlucky', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'scraperProject.png',
    title: 'Reddit FMF Deal Scraper',
    info: 'A script that scrapes /r/frugalmalefashion for deals and notifies the Reddit user of said deals in a Private Message',
    info2: 'I like to be frugal. But, others also like to be frugal. Sometimes when you find a great deal, that item is out of stock or the only size left is either way too small or way too large. I made this script to hopefully be ahead of the fashion mob but also to avoid having to refresh or check Reddit ever so often for new deals.',
    tech: 'Technologies: Python3, PRAW (Python Reddit API Wrapper)',
    url: '',
    repo: 'https://github.com/KennethNguyen/reddit-fmf-deal-scraper', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Please feel free to reach out through email or LinkedIn.',
  btn: '',
  email: 'n.kennethcs@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'envelope',
      url: '',
      email: 'n.kennethcs@gmail.com'
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/kenneth-nguyenn',
      email: ''
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/KennethNguyen',
      email: ''
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
